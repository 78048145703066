import { createGlobalStyle } from 'styled-components';

const onlyWithFile = edge => edge.fontFile.file;

const toCss = edge => {
    const fileUrl = edge.fontFile.file.url;
    const staticFileUrl = `/fonts/${ edge.name }-${ edge.weight[0].content }.${ fileUrl.substring(fileUrl.lastIndexOf('.') + 1) }`;

    return `@font-face {
                 font-family: '${ edge.name }' ;
                 font-style: ${ edge.style.toLowerCase() };
                 font-weight: ${ edge.weight[1].content };
                 font-display: swap;
                 src: local('${ edge.name }'), 
                      url(${ staticFileUrl }) 
                      format('truetype');
            }`;
};

const generateFontFaces = allFonts => allFonts
    .filter(onlyWithFile)
    .map(toCss)
    .join(' ');

const GlobalFonts = createGlobalStyle`     
   ${ props => generateFontFaces(props.fonts) }
`;
export default GlobalFonts;
