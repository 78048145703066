import React, { useEffect, useState } from 'react';

const StaticProviderSpeakers = ({ speakersURL, children }) => {
    const [speakers, setSpeakers] = useState(null);
    const [component, setComponent] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const dataSpeakers = await fetch(speakersURL).then(results => { return results.json(); });
            setSpeakers(dataSpeakers);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (speakers) {
            setComponent(React.cloneElement(children, { data: speakers }));
        }
    }, [speakers]);

    return component && component;
};

export default StaticProviderSpeakers;
