import React, { useEffect, useState } from 'react';

const StaticProviderSchedule = ({ scheduleURL, speakersURL, children }) => {
    const [schedule, setSchedule] = useState(null);
    const [speakers, setSpeakers] = useState(null);
    const [component, setComponent] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const dataSchedule = await fetch(scheduleURL).then(results => {
                return results.json();
            });
            setSchedule(dataSchedule);

            const dataSpeakers = await fetch(speakersURL).then(results => { return results.json(); });
            setSpeakers(dataSpeakers);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (schedule && speakers) {
            setComponent(React.cloneElement(children, { data: { schedule: schedule, speakers: speakers } }));
        }
    }, [schedule, speakers ]);

    return component && component;
};

export default StaticProviderSchedule;
