/* eslint-disable spaced-comment,no-trailing-spaces,object-curly-spacing,padded-blocks,no-multiple-empty-lines */
import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';

import {extractActiveValue } from 'utils/Extract';
import {initialize as initTagManager} from 'utils/TagManager';
import {initialize as initStructuredData} from 'utils/StructuredData';
import {getComponent, getSection} from 'utils/WebComponents';

import Structure from './Structure';
import Container from 'components/Container';
import HelmetHeader from 'components/HelmetHeader';
import StaticProviderSchedule from 'staticProviders/StaticProviderSchedule';
import StaticProviderSpeakers from 'staticProviders/StaticProviderSpeakers';

if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
}

const Generated = styled.div`
  display : flex;
  flex-direction: column;
`;


const PageTemplate = ({pageContext, data, location}) => {
    const {website} = data;
    const {structure} = data.page;
    const {slug, locale, locales, dataset} = pageContext;

    let websiteNode = website.edges[0].node;
    const favicon = websiteNode.favicon.file.fileName;
    const assetsPath = getAssetsPath(location.pathname);

    const dom = getDom(structure);
    const domNavigation = getDom(websiteNode.menuHeaderStructure);
    const domFooter = getDom(websiteNode.menuFooterStructure);

    const seo = JSON.parse(websiteNode.seo.seoConfiguration.value);
    const seoPage = getPageSEO(seo.pages, slug);


    useEffect(() => {
        const seo = getGlobalSEO(data);
        if (seo.global.GoogleTagManager) {
            initTagManager(seo.global.GoogleTagManager.head, seo.global.GoogleTagManager.body);
        }

        const seoPage = getPageSEO(seo.pages, slug);
        if (seoPage && seoPage.Data && seoPage.Data.structuredData) {
            initStructuredData(seoPage.Data.structuredData[locale]);
        }
    });

    return (
        <Structure>
            <HelmetHeader seo={seoPage} seoGlobal={seo.global} locale={locale}
                          assetsPath={assetsPath} favicon={favicon}/>
            <Container>
                {
                    domNavigation && domNavigation.map((section, i) => {
                        const children = section.components.map((component, j) => {
                            const customParams = {
                                menu: getEdgeByLocale(website.edges, locale).node.menuHeader,
                                location: location,
                                locales: locales,
                                locale: locale
                            };
                            return getComponent(component, pageContext, assetsPath, j, customParams);
                        });
                        return getSection(section, pageContext, assetsPath, i, children);
                    })
                }
                <Generated>
                    {
                        dom && dom.map((section, i) => {
                            const children = section.components.map((component, j) => getComponent(component, pageContext, assetsPath, j));

                            switch (true) {
                                case section.model.includes('Speakers'):
                                    return <StaticProviderSpeakers
                                        speakersIds={section.fields['Speakers'].content.speakers}
                                        speakersURL={dataset.speakers}>
                                        {getSection(section, pageContext, assetsPath, i, children)}
                                    </StaticProviderSpeakers>;

                                case section.model.includes('Schedule'):
                                    return <StaticProviderSchedule
                                        scheduleURL={dataset.schedule}
                                        speakersURL={dataset.speakers}>
                                        {getSection(section, pageContext, assetsPath, i, children)}
                                    </StaticProviderSchedule>;

                                default :
                                    return getSection(section, pageContext, assetsPath, i, children);
                            }
                        })
                    }
                </Generated>
                {
                    domFooter && domFooter.map((section, i) => {
                        const children = section.components.map((component, j) => {
                            const customParams = {
                                menu: getEdgeByLocale(website.edges, locale).node.menuFooter,
                                location: location,
                                locales: locales,
                                locale: locale
                            };
                            return getComponent(component, pageContext, assetsPath, j, customParams);
                        });
                        return getSection(section, pageContext, assetsPath, i, children);
                    })
                }
            </Container>
        </Structure>
    );
};


export const query = graphql`
    query($slug: String!, $project: String!) {
        page : contentfulPage(slug: { eq: $slug }, domain: {elemMatch: {slug: {eq: $project}}}) {
            name,
            slug,
            structure {
                ... on ContentfulDom {
                    sectionName
                    dom {
                        dom
                    }
                }
            }
        }
        website : allContentfulWebsite(filter: {slug: {eq: $project}}) {
            edges {
                node {
                    id
                    node_locale
                    favicon {
                        file {
                            fileName
                        }
                    }
                    menuHeader {
                        name
                        slug
                        type
                        urlLink
                        childrens {
                            name
                            slug
                            type
                            urlLink
                            childrens {
                                name
                                slug
                                type
                                urlLink
                            }
                        }
                    }
                    menuHeaderStructure {
                        dom {
                            dom
                        }
                    }
                    menuFooter {
                        name
                        slug
                        type
                        urlLink
                        childrens {
                            name
                            slug
                            type
                            urlLink
                            childrens {
                                name
                                slug
                                type
                                urlLink
                            }
                        }
                    }
                    menuFooterStructure {
                        dom {
                            dom
                        }
                    }
                    seo {
                        seoConfiguration {
                            value
                        }
                    }
                }
            }
        }
    }
`;

export default PageTemplate;

const getEdgeByLocale = (edges, locale) => edges.filter(edge => edge.node.node_locale === locale)[0];

const getAssetsPath = pathname => pathname === '/' ? './assets/' : `${ pathname.split('/').filter(Boolean).map(i => '../').join('') }assets/`;

const getDom = structure => structure && structure.dom ? extractActiveValue(JSON.parse(structure.dom.dom)) : null;

const getPageSEO = (pages, slug) => {
    let page = {};
    Object.keys(pages).map(key => {
        if (pages[key].slug[Object.keys(pages[key].slug)[0]] === slug) {
            page = pages[key];
            return page;
        }
    });
    return page;
};

const getGlobalSEO = data => JSON.parse(data.website.edges[0].node.seo.seoConfiguration.value);

