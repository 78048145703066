import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GlobalStyle from './GlobalStyle';
import GlobalFonts from './GlobalFonts';

const ThemeProvider = () => {
    const data = useStaticQuery(graphql`
        query themeData {
            styleGuide: allContentfulWebsite(filter: {slug: {eq: "website"}, styleGuide: {themes: {themes: {themes: {elemMatch: {}}}}}}) {
                edges {
                    node {
                        id
                        styleGuide {
                            typography {
                                name
                                typeface
                                style
                                weight {
                                    content
                                }
                                fontFile {
                                    id
                                    file {
                                        url
                                        fileName
                                        contentType
                                    }
                                }
                            }
                            themes {
                                themes {
                                    themes {
                                        family
                                        weight
                                        typeface
                                        name
                                        fontsize {
                                            A
                                            D
                                            M
                                            T
                                        }
                                        lineheight {
                                            A
                                            D
                                            M
                                            T
                                        }
                                    }
                                    base {
                                        fontsize
                                        lineheight
                                        ratio {
                                            A
                                            D
                                            M
                                            T
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const styleGuide = data.styleGuide.edges[0].node.styleGuide;
    const theme = styleGuide.themes.themes;
    const fonts = styleGuide.typography;
    return (
        <div>
            <GlobalFonts fonts={fonts}/>
            <GlobalStyle themes={theme.themes} base={theme.base}/>
        </div>
    );
};

export default ThemeProvider;
