import React from 'react';
import Helmet from 'react-helmet';
import { getFileNameFromUrl } from '../utils/CreatePageFromEdge';

const HelmetHeader = ({ seo, seoGlobal, locale, assetsPath, favicon }) => {
    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{seo.Base && seo.Base.title ? `${ seo.Base.title[locale] }` : ''}</title>
            <meta name="description"
                content={seo.Base && seo.Base.description ? `${ seo.Base.description[locale] }` : ''}/>
            <meta property="og:title"
                content={seo.OGP && seo.OGP.title ? `${ seo.OGP.title[locale] }` : ''}/>
            <meta property="og:type"
                content={seo.OGP && seo.OGP.type ? `${ seo.OGP.type[locale] }` : ''}/>
            <meta property="og:url"
                content={seo.OGP && seo.OGP.url ? `${ seo.OGP.url[locale] }` : ''}/>
            <meta property="og:image"
                content={seo.OGP && seo.OGP.image ? `/assets/${ getFileNameFromUrl(seo.OGP.image[locale]) }` : ''}/>
            <meta property="og:description"
                content={seo.OGP && seo.OGP.description ? `${ seo.OGP.description[locale] }` : ''}/>
            {
                seo.Base && seo.Base.noIndex && seo.Base.noIndex === true
                    ? <meta name="robots" content="noindex"/> : null
            }
            <link rel="icon" href={`${ assetsPath }${ favicon }`}/>
            <html lang="fr"
                prefix={seoGlobal && seoGlobal.OGP && seoGlobal.OGP.namespace ? `og: ${ seoGlobal.OGP.namespace }` : ''}/>
        </Helmet>
    );
};

export default HelmetHeader;
