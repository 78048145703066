const getLocalePath = (locales, locale) => (locales.length <= 1) ? '' : `${ locale.split('-')[0] }/`;

const checkQueryError = result => {
    if (result.errors) {
        throw new Error(result.errors);
    }
};

const getFileNameFromUrl = url => url.substring(url.lastIndexOf('/') + 1);

module.exports = {
    getLocalePath,
    getFileNameFromUrl,
    checkQueryError
};
