import React from 'react';
import ThemeProvider from 'styles/ThemeProvider';

const Structure = ({ children }) => {
    return (
        <>
            <main>
                {children}
            </main>
            <ThemeProvider/>
        </>
    );
};

export default Structure;
